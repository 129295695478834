import db from "../../../components/firebaseInit"
import firebase from "firebase";
import { showSnackbar } from "../../../globalActions/index";

export const attachImage = function () {
    this.loadingAttachment = true;
    this.displayErrorAlert = false;
    const imageFile = event.target.files[0];
    const storageRef = firebase
        .storage()
        .ref(`order-attachment/${imageFile.name}`).put(imageFile);
    let base64, w, h;
    // Prevent upload if no file is selected
    if (!imageFile.name) return;
    // Convert image to base64 so it can be used in PDFs
    const convertToBase64 = () => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            // Get image dimensions
            reader.onloadend = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = function () {
                    w = this.width;
                    h = this.height;
                    //  Resolve the promise
                    resolve(reader.result);
                };
            };
            reader.readAsDataURL(imageFile);
        });
    };
    convertToBase64()
        .then((img) => {
            base64 = img;
        })
        // Add image to firebase storage
        .then(() => {
            const batch = db.batch();
            storageRef.on(
                `state_changed`,
                (snapshot) => {
                    this.uploadValue =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                    console.log(error.message);
                    this.loadingAttachment = false;
                    this.displayErrorAlert = true;
                    showSnackbar("Failed to attach file");
                },
                () => {
                    storageRef.snapshot.ref.getDownloadURL().then((url) => {
                        const returnAttachments = () => {
                            const attachmentObj = {
                                name: `${imageFile.name}`,
                                src: url,
                                base64: base64,
                                w: w,
                                h: h,
                            };
                            return this.item.attachments
                                ? this.item.attachments.concat([attachmentObj])
                                : [attachmentObj];
                        };
                        const docRef = db.doc(`sales_orders/${this.item.order_id}`);
                        batch.update(docRef, {
                            attachments: returnAttachments(),
                        });
                        if (this.item.work_order_id) {
                            const workOrderRef = db.doc(`work_orders/${this.item.work_order_id}`)
                            batch.update(workOrderRef, {
                                attachments: returnAttachments(),
                            })
                        }
                        batch.commit().then(() => {
                            this.loadingAttachment = false;
                            showSnackbar("Image attached successfully!");
                            this.uploadValue = null;
                        })
                    });
                }
            );
        })
}