<template>
  <v-dialog
    v-model="attachments_dialog[item.order_id]"
    width="950"
    max-width="950"
  >
    <v-card>
      <v-card-title class="mb-3">
        <h4>Attachments</h4>
        <!--Close Button-->
        <v-spacer />
        <v-btn
          small
          icon
          @click="$set(attachments_dialog, item.order_id, false)"
          ><v-icon small>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-data-table
        :items="item.attachments"
        :headers="headers"
        hide-default-footer
      >
        <!--Attachment Link-->
        <template v-slot:item.name="{ item }">
          <a :href="item.src" target="blank" style="text-decoration: none">{{
            item.name
          }}</a>
        </template>
        <!--Action Buttons-->
        <template v-slot:item.base64="{ item }">
          <v-btn
            @click="deleteAttachment(item)"
            icon
            small
            color="error"
            :loading="loadingDelete[item.src]"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
        <!--Empty state-->
        <template v-slot:no-data> No attachments uploaded </template>
        <template v-slot:top>
          <!--Progress Bar-->
          <v-container>
            <v-progress-linear
              :value="uploadValue"
              v-if="!!uploadValue"
              striped
              height="10"
              color="primary"
            ></v-progress-linear>
          </v-container>
          <!--Error Message-->
          <v-alert v-if="displayErrorAlert" class="ma-3" text type="error"
            >Failed to upload image</v-alert
          ></template
        >
      </v-data-table>
      <v-card-actions class="py-3">
        <v-container>
          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!--Add attachment Button-->
              <v-btn
                color="primary float-right"
                elevation="0"
                :loading="loadingAttachment"
                @click="$refs.imageUpload.click()"
                ><v-icon left small>mdi-plus</v-icon> New Attachment</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
      <!--Image Upload-->
      <input
        type="file"
        ref="imageUpload"
        @change="attachImage()"
        style="display: none"
        accept="image/*"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import firebase from "firebase";
import db from "../../../../components/firebaseInit";
import { attachImage } from "../../data/external_image_attachments";
import { showSnackbar } from "../../../../globalActions";
export default {
  name: "ViewAttachmentsModal",
  props: ["attachments_dialog", "item"],
  data() {
    return {
      loadingAttachment: false,
      loadingDelete: {},
      displayErrorAlert: false,
      uploadValue: null,
      headers: [
        { text: "File Name", value: "name", align: "start" },
        { text: "Actions", value: "base64", align: "right" },
      ],
    };
  },
  methods: {
    attachImage,
    deleteAttachment(el) {
      if (confirm("Are you sure you want to delete this attachment?")) {
        const batch = db.batch();
        // Toggle delete state
        this.$set(this.loadingDelete, el.src, true);
        // Remove image from firestore doc field
        const returnValue = () => {
          return this.item.attachments.length > 1
            ? firebase.firestore.FieldValue.arrayRemove(el)
            : firebase.firestore.FieldValue.delete();
        };
        // Remove image from cloud storage
        const fileRef = firebase.storage().ref(`order-attachment/${el.name}`);
        fileRef
          .delete()
          .then(() => {
            const docRef = db.doc(`sales_orders/${this.item.order_id}`);
            batch.update(docRef, { attachments: returnValue() });

            if (this.item.work_order_id) {
              const workOrderRef = db.doc(
                `work_orders/${this.item.work_order_id}`
              );
              batch.update(workOrderRef, { attachments: returnValue() });
            }
            batch
              .commit()
              .then(() => showSnackbar("Attachment deleted successfully"));
          })
          .catch((error) => error);
      }
    },
  },
};
</script>